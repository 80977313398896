.colBox {
  @include flex;
  @include flexWrap(wrap);
  @include pc{
    @include justifyContent(center);
  }
  &.col1{
    li{
      margin-bottom: 0;
      @include pc{
        width: 47.5%;
      }
      @include sp{
        width: 100%;
        margin-right: 0%;
      }
      &:nth-child(even){
        @include sp{
          margin-right: 0;
        }
      }
      &:nth-child(3n) {
        @include pc{
          margin-right: 0;
        }
      }
      figure,.img{
        // max-height: 375px;
        // img{
        //   max-height: 375px;
        // }
        @include sp {
          max-height: none;
          img {
            width: 100%;
            max-height: none;
          }
        }
      }
    }
  }
  &.col2{
    li{
      @include pc{
        width: 47.5%;
        margin-right: 2.5%;
      }
      @include sp{
        width: 100%;
        margin-right: 0%;
      }
      &:nth-child(even){
        @include sp{
          margin-right: 0;
        }
      }
      &:nth-child(3n) {
        @include pc{
          margin-right: 0;
        }
      }
    }
  }
  &.col3{
    @include sp{
      @include justifyContent(center);
    }
    li{
      @include pc{
        width: 30.6%;
        margin-right: 4.1%;
      }
      @include sp{
        width: 100%;
        margin-right: 0%;
      }
      &:nth-child(even){
        @include sp{
          margin-right: 0;
        }
      }
      &:nth-child(3n) {
        @include pc{
          margin-right: 0;
        }
      }
    }
  }
  &.col4{
    @include sp{
      @include justifyContent(center);
    }
    li{
      @include pc{
        width: 22.5%;
        margin-right: 3.3%;
      }
      @include sp{
        width: 46.2%;
        margin-right: 7.4%;
      }
      &:nth-child(even){
        @include sp{
          margin-right: 0;
        }
      }
      &:nth-child(4n) {
        @include pc{
          margin-right: 0;
        }
      }
  
    }
  }
  & > li {
    margin-bottom: 20px;
    &>a {
      display: block;
      text-decoration: none;
      color: #323232;
      &:hover{
        figure{
          img{
            @include pc{
              transform: scale(1.1);
            }
          }
        }
      }
    }

    figure {
      display: block;
      text-align: center;
      margin-bottom: 15px;
      overflow: hidden;
      width: 270px;
      // height: 270px;
      @media  screen and (min-width: 749px) and (max-width: 1230px) {
        max-width: 100%;
        // height: 20vw;
      }
      @include sp{
        width: 100%;
        margin-bottom: 0px;
        // height: 45vw;
      }
      img{
        transform: scale(1);
        transition: transform 0.3s;
        width: 100%;
      }
    }

    .date {
      font-size: 1.4rem;
      line-height: 1;
      margin-bottom: 10px;
    }

    .ttl {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2;
      @include sp{
        line-height: 1.8;
      }
    }

  }
}