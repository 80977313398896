.fadeUp {
  opacity: 0;

  &.animated {

    position: relative;
    animation-name: fadeup;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-delay: 0s;

    &.delay1 {
      animation-delay: 0.5s;
    }

    &.delay2 {
      animation-delay: 1s;
    }
  }
}

.leftIn {
  opacity: 0;

  &.animated {

    @include tab {
      position: relative;
      animation-name: fadeup;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      animation-delay: 0s;
    }

    @include sp {
      position: relative;
      animation-name: fadeup;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      animation-delay: 0s;
    }

    &.delay1 {
      animation-delay: 0s;
    }

    &.delay2 {
      animation-delay: 0s;
    }

  }
}

.rightIn {
  opacity: 0;
  &.animated {

    @include tab {
      position: relative;
      animation-name: fadeup;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      animation-delay: 0s;
    }

    @include sp {
      position: relative;
      animation-name: fadeup;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      animation-delay: 0s;
    }

    &.delay1 {
      animation-delay: 0s;
    }

    &.delay2 {
      animation-delay: 0s;
    }

  }
}

.bothIn {
  .leftIn {
    opacity: 0;
  }

  .rightIn {
    opacity: 0;
  }

  &.animated {
    .leftIn {
      @include pc {
        position: relative;
        animation-name: sideLeft;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
      }
      @include tab {
        position: relative;
        animation-name: fadeup;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
      }

      &.animated {

        @include tab {}

        @include sp {}

      }
    }

    .rightIn {
      @include pc {
        position: relative;
        animation-name: sideRight;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
      }
      @include tab {
        position: relative;
        animation-name: fadeup;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
      }

      &.animated {

        @include tab {}

        @include sp {}

      }
    }
  }
}

@keyframes fadeup {
  0% {
    opacity: 0;
    bottom: -50px;
  }

  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes sideLeft {
  0% {
    opacity: 0;
    left: -50px;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes sideRight {
  0% {
    opacity: 0;
    right: -50px;
  }

  100% {
    opacity: 1;
    right: 0;
  }
}