.conditionsBox {
  border-radius: 45px;
  background: #f2f2f2;
  padding: 30px 5.4%;
  margin-bottom: 80px;

  @include sp {
    padding: 0px 7.4% 25px;
    border-radius: 27px;
    margin-bottom: 50px;
  }

  .acoTabBtn {
    font-size: 1.8rem;
    font-weight: bold;
    padding: 19px 0;
  }

  .acoTabItem {
    &.active {
      display: block;
    }
  }

  dl {
    @include flex;
    @include alignItems(start);

    @include sp {
      display: block;
    }

    dt {
      width: 250px;
      font-size: 2.4rem;
      font-family: $font-zenmaru;
      font-weight: bold;

      @include sp {
        width: 100%;
        font-size: 2.2rem;
        padding-top: 25px;
        margin-bottom: 25px;
      }
    }

    dd {
      width: calc(100% - 250px);

      @include sp {
        width: 100%;
      }
    }

    &+dl {
      margin-top: 20px;

      @include sp {
        margin-top: 10px;
      }
    }
  }

  &Btnwrap {
    @include flex;
    @include flexWrap(wrap);
    @include alignItems(start);
    @include justifyContent(sb);
    margin-top: 20px;

    @include sp {
      display: block;
      text-align: center;
    }

    .allKeyword {
      padding-left: 24.3%;
      text-align: center;
      cursor: pointer;
      @include flex;
      @include alignItems(center);

      @include tab {
        width: 100%;
        padding-left: 0;
      }

      @include sp {
        display: inline-block;
        margin: 0 auto;
        padding-left: 0;
      }

      span {
        display: inline-block;
        position: relative;
        padding-right: 2em;
        &::before,
        &::after {
          content: '';
          display: inline-block;
          background: #323232;
          margin-left: 20px;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          border-radius: 25%;
          opacity: 1;
          transition: 0.3s;
        }

        &::before {
          width: 14px;
          height: 1px;
          right: 0.5em;
          transform: rotate(-90deg);
        }

        &::after {
          width: 14px;
          height: 1px;
          right: 0.5em;
          opacity: 1;
          transition: 0.3s;
          
        }
      }
      &.active{
        span{
          &::before {
            opacity: 0;
            transform: rotate(0deg);
          }
        }
      }
    }

    .allCancel {
      text-align: right;
      @include flex;
      @include alignItems(start);
      padding-left: 20px;
      margin-left: auto;

      @include sp {
        @include justifyContent(end);
        padding-left: 0;
        margin-top: 20px;
      }

      label {
        display: block;
        cursor: pointer;
      }
    }
  }
}

.checkTags {
  @include flex;
  @include flexWrap(wrap);

  li {
    margin-bottom: 20px;
    margin-right: 20px;

    @include sp {
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }

  label {
    font-size: 1.6rem;
    line-height: 2;
    display: inline-block;
    background: #fff;
    padding: 0 1em;
    border-radius: 9999px;
    vertical-align: middle;
    cursor: pointer;

    @include sp {
      font-size: 1.4rem;
    }

    &:hover {
      background: $darkgray;
    }
  }


  input:checked+label {
    background: $darkgray;
  }

}

.checkWord {
  @include flex;
  @include flexWrap(wrap);

  li {
    margin-bottom: 16px;
    margin-right: 32px;

    @include sp {
      margin-right: 30px;
      margin-bottom: 20px;
    }
  }

  label {
    font-size: 1.6rem;
    line-height: 2;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    border-bottom: 1px solid transparent;

    @include sp {
      font-size: 1.4rem;
    }

    &:hover {
      border-bottom: 1px solid #323232;
    }
  }

  input:checked+label {
    border-bottom: 1px solid #323232;
  }
}