.imgBox {
  .inner {
    @include flex;
    @include alignItems(start);
    @include justifyContent(end);
    @include sp{
      @include flexDirection(col);
      @include alignItems(center);
    }
    &.reverse {
      @include flexDirection(rowR);
      @include justifyContent(start);
      @include sp {
        @include flexDirection(col);
        @include alignItems(center);
      }
      .img {
        margin-right: 4%;
        margin-left: 0;
        @include sp{
          margin-right: 0;
        }
      }
    }
  }

  .txtBox {
    width: 100%;
    p {
      line-height: 2;
    }
  }

  .img {
    width: 50%;
    max-width: 500px;
    display: block;
    margin-left: 4%;
    flex-shrink: 0;
    @include sp{
      width: 100%;
      order: -1;
      margin-left: 0;
      margin-bottom: 25px;
      img { width: 100%; }
    }
  }
}