.searchResultNum {
  font-size: 1.8rem;
  margin-bottom: 30px;

  @include sp {
    margin-bottom: 25px;
  }

  &>span {
    font-weight: bold;
  }
}

.searchResult {
  @include flex;
  @include flexWrap(wrap);

  @include sp {
    display: block;
  }
}

.searchResultItem {
  margin-bottom: 20px;
  width: 22.5%;
  margin-right: 3.3%;

  @include sp {
    width: 100%;
    margin-bottom: 0;
    padding: 20px 0;
    border-bottom: 1px solid #e0e0e0;
  }


  &:nth-child(4n) {
    margin-right: 0;
  }

  &>a {
    display: block;
    text-decoration: none;
    color: #323232;

  }

  & > a[target="_blank"] {
    .ttl {
      display: inline-block;
      position: relative;
      &::after {
        content: '';
        background: url(/common/images/icon_externalBlack.svg) no-repeat;
        width: 0.75em;
        height: 0.75em;
        margin-left: 10px;
        transition: .3s;
        display: inline-block;
      }
    }
  }



  figure {
    display: block;
    margin-bottom: 15px;

    @include sp {
      width: 46.2%;
      margin-right: 4.4%;
    }
  }

  .txtBox {
    @include sp {
      width: 49.2%;
    }
  }

  .inner {
    @include sp {
      @include flex;
      @include alignItems(start);
    }
  }

  .cate {
    span {
      font-size: 1.6rem;
      background: $lightgray;
      vertical-align: middle;
      padding: 0 1em;
      line-height: 2;
      border-radius: 9999px;
      display: inline-block;
      margin-right: 12px;
      margin-bottom: 12px;

      @include sp {
        font-size: 1.4rem;
      }
    }
  }

  .ttl {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2;

    @include sp {
      line-height: 1.8;
    }
  }

  .txt {
    font-size: 1.6rem;
    line-height: 2;
    margin: 0;

    @include sp {
      line-height: 1.8;
    }
  }
}

.relateKeyword{
  font-weight: normal !important;
  @include pc{
    white-space: nowrap;
    min-width: 128px;
  }
}