//font
$noto-sans: 'Noto Sans JP', sans-serif;
$noto-serif: 'Noto Sans JP', sans-serif;
$font-CG: "Century Gothic", "Noto Sans JP", sans-serif;
$font-Ubuntu: 'Ubuntu', sans-serif;
$font-zenmaru: 'Zen Maru Gothic', serif;
$thin:100;
$light:300;
$regular:400;
$medium:500;
$bold:700;

//media
$content: 1280;
$contentPx: $content * 1px;
$breakpoint-sp: 749px;

//color
$blue: #2ea7e0;
$pink: #ee3ea0;
$orange: #f39700;
$green: #8fc31f;
$purple: #69318e;
$lightgray: #f2f2f2;
$darkgray: #cdcdcd;
