.breadcrumb{
  padding-left: 40px;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
  @include pc{
    max-width: 1240px;
  }
  @include sp{
    font-size: 1rem;
    padding-left: 20px;
    letter-spacing: 0.025em;
  }

  &.sp-maxFull {
    @include sp {
      max-width: 100%;
    }
  }

  a,
  .arrow{
    color: #323232;
  }

  .current{
    color: #9f9f9f;
  }

  a{
    text-decoration: none;
  }
  .arrow{
    padding: 0px 8px;
  }
  
  &.sp-maxFull {
    @include sp {
      max-width: 100%;
    }
  }
}