.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: none; 
  z-index: 100;
  .inner{
    position: relative;
  }
  .modal-slider{
    &.only1{
      .slick-slide {
        min-width: 100%;
      }
    }
  }
  .slick-track{
    min-width: 100%;
    @include flex;
    @include justifyContent(center);
    @include alignItems(center);
  }
  .slick-arrow{
    background: red;
  }
}

.main-slider{
  img{
    max-width: 500px;
  }
}

