@mixin large {
  @media screen and (min-width: (1401px )) {
    @content;
  }
}

@mixin pc {
  @media screen and (min-width: ($breakpoint-sp + 1 )) {
    @content;
  }
}

@mixin headerTab {
  @media screen and (min-width: ($breakpoint-sp)) and (max-width: 1214px) {
    @content;
  }
}

@mixin tab {
  @media  screen and (min-width: ($breakpoint-sp)) and (max-width: 1023px) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: $breakpoint_sp) {
    @content;
  }
}