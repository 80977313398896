.tabContents{
 position: relative;
 z-index: 0;
 background-color: #edf3e2;
 width: 100%;
 padding-bottom: 80px;

 @include sp{
  padding-bottom: 35px;
 }

 .tab{
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  max-width: 830px;
  // width: 69%;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;

  @include sp{
   width: 100%;
  }
  li{
    width: 24%;
    transform: translate(0,20px);
    background-color: $green;
    color: #fff;
    transition: .4s;
    border-radius: 12px 12px 0px 0px;
   @include sp{
    min-width: 23.8%;
    width: 4.68%;
    transform: translate(0,15px);
   }

   &:hover{
    transform: translate(0,0);
    background-color: $green;
    color: #fff;
    a{
      color: #fff;
    }
   }
 
   span,a{
    padding: 20px 0px 40px;
    color: #fff; 
    text-decoration: none;
    display: inline-block;
    max-width: 200px;
    width: 100%;
    font-size: 2rem;
    text-align: center;
    font-family: $font-zenmaru;
    font-weight: $bold;
    letter-spacing: 0.005em;
    cursor: pointer;
    margin-right: 10px;
    &:last-of-type{
     margin-right: 0;
    }
     @include sp{
      width: 100%;
      font-size: 1.6rem;
      padding: 15px 0px 30px;
      margin-right: 5px;
     }
   }

   &.current{
     background-color: #fff;
     transform: translate(0,0);
     color: $green;
     span,a{
      color: $green;
     }
   }
  }
 }

 .area{
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  break-before: 10px;
  padding: 80px 8.3%;
  border-radius: 10px;
 
  @include sp{
   padding: 35px 8.9%; 
   border-radius: 0 0 10px 10px;
  }
  h2{
   width: 100%;
   @include sp{
    margin-top: 35px;
   }
  }
  .introImg{
   width: 46%;
   margin-right: 4%;
   @include sp{
    width: 100%;
    margin-right: 0%;
  }
  .img{
    width: 100%;
    max-width: 460px;
    margin-right: auto;
    margin-left: auto;
    background-color: #323232;
   }

   .txtBox{
    margin-top: 20px;
   }
  }

  .introTxt{
   width: 50%;
   @include sp{
    width: 100%;
    margin-top: 30px;
   }

   p{
    margin-bottom: 3.75em;
    &:last-of-type{
     margin-bottom: 0em;
    }
   }
  }
 }
}

// タブ内のスライダー専用
@media  screen and (min-width: ($breakpoint-sp)) and (max-width: 1110px){
  .tabContents{
    .sliderNav{
      position: relative;
      padding-bottom: 60px + 20px;

      .slick-arrow{
        position: absolute;
        bottom: 0;
        &.slick-prev{ margin-right: 96px; }
        &.slick-next{ margin-left: 96px; }
      }
    }
  }
}

.insidePageTabCnt{
  display: none;
  &.active{
    display: block;
  }
}