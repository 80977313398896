.migration {
  position: relative;

  @include sp{
    right: 0;
    z-index: 10;
  }

  .inner{
    @include pc{
      border-top: 1px solid #dfdfdf;
      border-bottom: 1px solid #dfdfdf;
      background-color: #fff;
      position: absolute;
      top: 0;
      width: 100%;
    }
    @include sp {
      position: relative;
      top: 0 !important;
    }
  }

  &Btn {
    @include pc {
      display: none;
    }

    @include sp {
      @include flex;
      @include justifyContent(center);
      @include alignItems(center);
      width: 60px;
      height: 60px;
      background-color: #fff;
      border: 1px solid #dfdfdf;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 1;
      z-index: 10;
    }

    span {
      @include sp {
        position: relative;
        cursor: pointer;
        display: block;
        width: 100%;
      }

      &::before,
      &::after {
        @include sp {
          content: '';
          display: inline-block;
          background: #23318e;
          border-radius: 25%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

      &::before {
        @include sp {
          width: 20px;
          height: 1px;
        }
      }

      &::after {
        @include sp {
          content: '';
          width: 20px;
          height: 1px;
          transition: 0.3s;
          opacity: 1;
          transform: rotate(90deg);
        }
      }
    }

    &.open {
      span {
        &::after {
          @include sp {
            opacity: 0;
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  ul {
    @include flex;
    @include flexWrap(wrap);
    @include justifyContent(center);
    gap: .8em 2.6em;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;

    padding: 30px 40px;
    width: 100%;
    @include sp {
      @include justifyContent(start);
      padding: 50px 5.3%;
      gap: .6em 2.2em;
      opacity: 0;
      position: absolute;
      top: 0;
      right: -20%;
      pointer-events: none;
      transition: right 0.3s, opacity 0.3s;
    }

    &.mw830 {
      max-width: 830px;
    }

    li {
      font-size: 1.6rem;

      a {
        position: relative;
        display: inline-block;
        color: #323232;
        text-decoration: none;
        line-height: 1;
        z-index: 1;

        &:before {
          content: '';
          position: absolute;
          top: calc(50% + .07em);
          left: 50%;
          transform: translate(-50%, -50%);
          width: calc(100% + 2.2em);
          height: calc(100% + .9em);
          background: #f7eedf;
          border-radius: 15px;
          z-index: -1;
          opacity: 0;
          pointer-events: none;
          transition: .3s;
        }

        &:hover {
          &:before { opacity: 1; }
        }
      }

      &.current {
        span {
          display: inline-block;
          color: $orange;
          text-decoration: none;
          font-weight: $bold;
        }
      }
    }
  }

  &.active{
    ul{
      @include sp{
        opacity: 1;
        right: 0;
        pointer-events: visible;
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
        background-color: #fff;
      }
    }
  }

  // ヘッダー固定
  &.fixed {
    @include sp{
      position: fixed;
      left: 0;
      z-index: 10;
    }
    .inner{
      @include pc{
        position: fixed;
        left: 0;
        z-index: -1;
        opacity: 0;
        transition: opacity .3s, z-index 0s .3s;
      }
    }
    &.active {
      .inner{
        @include pc{
          z-index: 10;
          opacity: 1;
          transition: opacity .3s, z-index 0s 0s;
        }
      }
    }
  }
}