.acoTabBtn {
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    width: 20px;
    display: inline-block;
    background: #c07e7e;
    border-radius: 25%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    height: 2px;

    @include sp {
      right: 5.3%;
    }
  }

  &::after {
    content: '';
    width: 20px;
    height: 2px;
    display: inline-block;
    background: #323232;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    transform: rotate(-90deg);
    opacity: 1;
    transition: 0.3s;
    border-radius: 25%;

    @include sp {
      right: 5.3%;
    }
  }

  &.active {
    &::after {
      opacity: 0;
      transform: rotate(0deg);
    }
  }

}

.acoTabItem {
  display: none;

  &.active {
    display: block;
  }
}

.cirAcoTabBtn {
  position: relative;
  cursor: pointer;
  padding: 15px 40px;
  border-radius: 50px;
  background: #fff;
  display: inline-flex;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  width: 100%;

  @include tab {
    padding-right: 60px;
  }

  @include sp {
    padding: 15px 30px;
    border-radius: 30px;
    padding-right: 50px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  }

  &::before {
    content: '';
    width: 20px;
    display: inline-block;
    background: #323232;
    border-radius: 25%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    height: 2px;

    @include sp {
      right: 5.3%;
    }
  }

  &::after {
    content: '';
    width: 20px;
    height: 2px;
    display: inline-block;
    background: #323232;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    transform: rotate(-90deg);
    opacity: 1;
    transition: 0.3s;
    border-radius: 25%;

    @include sp {
      right: 5.3%;
    }
  }

  span {
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 0.05em;

    @include sp {
      font-size: 1.5rem;
      line-height: 1.46666em;
    }

    &.letter {
      margin-right: 15px;
      display: inline-block;
      color: var(--vcolor);
    }
  }

  &.active {
    &::after {
      opacity: 0;
      transform: rotate(0deg);
    }
  }

}

.cirAcoTabItem {
  display: none;
  padding: 15px 40px;
  display: none;
  margin-bottom: -25px;

  @include sp {
    padding: 15px 30px;
    border-radius: 30px;
  }

  &.active {
    display: block;
  }

  span {
    font-size: 1.6rem;
    line-height: 32px;
    @include sp {
      line-height: 1.8em;
    }

    &.letter {
      font-size: 1.8rem;
      color: #2ea7e0;
      font-weight: 700;
      margin-right: 15px;
      display: inline-block;
    }
  }
}

