.m {
  &-left{
    text-align: left;
  }
  &-center{
    text-align: center;
    &-spleft{
      text-align: center;
      @include sp{
        text-align: left;
      }
    }
  }
  &-right{
    text-align: right;
  }

  &-mt5 {
    @include pc {
      margin-top: 5px;
    }
  }

  &-mtsp5 {
    @include sp {
      margin-top: 5px;
    }
  }

  &-mt10 {
    @include pc {
      margin-top: 10px;
    }
  }

  &-mtsp10 {
    @include sp {
      margin-top: 10px;
    }
  }

  &-mt15 {
    @include pc {
      margin-top: 15px;
    }
  }

  &-mtsp15 {
    @include sp {
      margin-top: 15px;
    }
  }

  &-mt20 {
    @include pc {
      margin-top: 20px;
    }
  }

  &-mtsp20 {
    @include sp {
      margin-top: 20px;
    }
  }

  &-mt25 {
    @include pc {
      margin-top: 25px;
    }
  }

  &-mtsp25 {
    @include sp {
      margin-top: 25px;
    }
  }

  &-mt30 {
    @include pc {
      margin-top: 30px;
    }
  }

  &-mtsp30 {
    @include sp {
      margin-top: 30px;
    }
  }

  &-mt35 {
    @include pc {
      margin-top: 35px;
    }
  }

  &-mtsp35 {
    @include sp {
      margin-top: 35px;
    }
  }

  &-mt40 {
    @include pc {
      margin-top: 40px;
    }
  }

  &-mtsp40 {
    @include sp {
      margin-top: 40px;
    }
  }

  &-mt45 {
    @include pc {
      margin-top: 45px;
    }
  }

  &-mtsp45 {
    @include sp {
      margin-top: 45px;
    }
  }

  &-mt50 {
    @include pc {
      margin-top: 50px;
    }
  }

  &-mtsp50 {
    @include sp {
      margin-top: 50px;
    }
  }

  &-mt55 {
    @include pc {
      margin-top: 55px;
    }
  }

  &-mtsp55 {
    @include sp {
      margin-top: 55px;
    }
  }

  &-mt60 {
    @include pc {
      margin-top: 60px;
    }
  }

  &-mtsp60 {
    @include sp {
      margin-top: 60px;
    }
  }

  &-mt65 {
    @include pc {
      margin-top: 65px;
    }
  }

  &-mtsp65 {
    @include sp {
      margin-top: 65px;
    }
  }

  &-mt70 {
    @include pc {
      margin-top: 70px;
    }
  }

  &-mtsp70 {
    @include sp {
      margin-top: 70px;
    }
  }

  &-mt75 {
    @include pc {
      margin-top: 75px;
    }
  }

  &-mtsp75 {
    @include sp {
      margin-top: 75px;
    }
  }

  &-mt80 {
    @include pc {
      margin-top: 80px;
    }
  }

  &-mtsp80 {
    @include sp {
      margin-top: 80px;
    }
  }

  &-mt85 {
    @include pc {
      margin-top: 85px;
    }
  }

  &-mtsp85 {
    @include sp {
      margin-top: 85px;
    }
  }

  &-mt90 {
    @include pc {
      margin-top: 90px;
    }
  }

  &-mtsp90 {
    @include sp {
      margin-top: 90px;
    }
  }

  &-mt95 {
    @include pc {
      margin-top: 95px;
    }
  }

  &-mtsp95 {
    @include sp {
      margin-top: 95px;
    }
  }

  &-mt100 {
    @include pc {
      margin-top: 100px;
    }
  }

  &-mtsp100 {
    @include sp {
      margin-top: 100px;
    }
  }

  &-mr5 {
    @include pc {
      margin-right: 5px;
    }
  }

  &-mrsp5 {
    @include sp {
      margin-right: 5px;
    }
  }

  &-mr10 {
    @include pc {
      margin-right: 10px;
    }
  }

  &-mrsp10 {
    @include sp {
      margin-right: 10px;
    }
  }

  &-mr15 {
    @include pc {
      margin-right: 15px;
    }
  }

  &-mrsp15 {
    @include sp {
      margin-right: 15px;
    }
  }

  &-mr20 {
    @include pc {
      margin-right: 20px;
    }
  }

  &-mrsp20 {
    @include sp {
      margin-right: 20px;
    }
  }

  &-mr25 {
    @include pc {
      margin-right: 25px;
    }
  }

  &-mrsp25 {
    @include sp {
      margin-right: 25px;
    }
  }

  &-mr30 {
    @include pc {
      margin-right: 30px;
    }
  }

  &-mrsp30 {
    @include sp {
      margin-right: 30px;
    }
  }

  &-mr35 {
    @include pc {
      margin-right: 35px;
    }
  }

  &-mrsp35 {
    @include sp {
      margin-right: 35px;
    }
  }

  &-mr40 {
    @include pc {
      margin-right: 40px;
    }
  }

  &-mrsp40 {
    @include sp {
      margin-right: 40px;
    }
  }

  &-mr45 {
    @include pc {
      margin-right: 45px;
    }
  }

  &-mrsp45 {
    @include sp {
      margin-right: 45px;
    }
  }

  &-mr50 {
    @include pc {
      margin-right: 50px;
    }
  }

  &-mrsp50 {
    @include sp {
      margin-right: 50px;
    }
  }

  &-mr55 {
    @include pc {
      margin-right: 55px;
    }
  }

  &-mrsp55 {
    @include sp {
      margin-right: 55px;
    }
  }

  &-mr60 {
    @include pc {
      margin-right: 60px;
    }
  }

  &-mrsp60 {
    @include sp {
      margin-right: 60px;
    }
  }

  &-mr65 {
    @include pc {
      margin-right: 65px;
    }
  }

  &-mrsp65 {
    @include sp {
      margin-right: 65px;
    }
  }

  &-mr70 {
    @include pc {
      margin-right: 70px;
    }
  }

  &-mrsp70 {
    @include sp {
      margin-right: 70px;
    }
  }

  &-mr75 {
    @include pc {
      margin-right: 75px;
    }
  }

  &-mrsp75 {
    @include sp {
      margin-right: 75px;
    }
  }

  &-mr80 {
    @include pc {
      margin-right: 80px;
    }
  }

  &-mrsp80 {
    @include sp {
      margin-right: 80px;
    }
  }

  &-mr85 {
    @include pc {
      margin-right: 85px;
    }
  }

  &-mrsp85 {
    @include sp {
      margin-right: 85px;
    }
  }

  &-mr90 {
    @include pc {
      margin-right: 90px;
    }
  }

  &-mrsp90 {
    @include sp {
      margin-right: 90px;
    }
  }

  &-mr95 {
    @include pc {
      margin-right: 95px;
    }
  }

  &-mrsp95 {
    @include sp {
      margin-right: 95px;
    }
  }

  &-mr100 {
    @include pc {
      margin-right: 100px;
    }
  }

  &-mrsp100 {
    @include sp {
      margin-right: 100px;
    }
  }

  &-ml5 {
    @include pc {
      margin-left: 5px;
    }
  }

  &-mlsp5 {
    @include sp {
      margin-left: 5px;
    }
  }

  &-ml10 {
    @include pc {
      margin-left: 10px;
    }
  }

  &-mlsp10 {
    @include sp {
      margin-left: 10px;
    }
  }

  &-ml15 {
    @include pc {
      margin-left: 15px;
    }
  }

  &-mlsp15 {
    @include sp {
      margin-left: 15px;
    }
  }

  &-ml20 {
    @include pc {
      margin-left: 20px;
    }
  }

  &-mlsp20 {
    @include sp {
      margin-left: 20px;
    }
  }

  &-ml25 {
    @include pc {
      margin-left: 25px;
    }
  }

  &-mlsp25 {
    @include sp {
      margin-left: 25px;
    }
  }

  &-ml30 {
    @include pc {
      margin-left: 30px;
    }
  }

  &-mlsp30 {
    @include sp {
      margin-left: 30px;
    }
  }

  &-ml35 {
    @include pc {
      margin-left: 35px;
    }
  }

  &-mlsp35 {
    @include sp {
      margin-left: 35px;
    }
  }

  &-ml40 {
    @include pc {
      margin-left: 40px;
    }
  }

  &-mlsp40 {
    @include sp {
      margin-left: 40px;
    }
  }

  &-ml45 {
    @include pc {
      margin-left: 45px;
    }
  }

  &-mlsp45 {
    @include sp {
      margin-left: 45px;
    }
  }

  &-ml50 {
    @include pc {
      margin-left: 50px;
    }
  }

  &-mlsp50 {
    @include sp {
      margin-left: 50px;
    }
  }

  &-ml55 {
    @include pc {
      margin-left: 55px;
    }
  }

  &-mlsp55 {
    @include sp {
      margin-left: 55px;
    }
  }

  &-ml60 {
    @include pc {
      margin-left: 60px;
    }
  }

  &-mlsp60 {
    @include sp {
      margin-left: 60px;
    }
  }

  &-ml65 {
    @include pc {
      margin-left: 65px;
    }
  }

  &-mlsp65 {
    @include sp {
      margin-left: 65px;
    }
  }

  &-ml70 {
    @include pc {
      margin-left: 70px;
    }
  }

  &-mlsp70 {
    @include sp {
      margin-left: 70px;
    }
  }

  &-ml75 {
    @include pc {
      margin-left: 75px;
    }
  }

  &-mlsp75 {
    @include sp {
      margin-left: 75px;
    }
  }

  &-ml80 {
    @include pc {
      margin-left: 80px;
    }
  }

  &-mlsp80 {
    @include sp {
      margin-left: 80px;
    }
  }

  &-ml85 {
    @include pc {
      margin-left: 85px;
    }
  }

  &-mlsp85 {
    @include sp {
      margin-left: 85px;
    }
  }

  &-ml90 {
    @include pc {
      margin-left: 90px;
    }
  }

  &-mlsp90 {
    @include sp {
      margin-left: 90px;
    }
  }

  &-ml95 {
    @include pc {
      margin-left: 95px;
    }
  }

  &-mlsp95 {
    @include sp {
      margin-left: 95px;
    }
  }

  &-ml100 {
    @include pc {
      margin-left: 100px;
    }
  }

  &-mlsp100 {
    @include sp {
      margin-left: 100px;
    }
  }

  &-mb0 {
    margin-bottom: 0!important;
  }

  &-mb5 {
    @include pc {
      margin-bottom: 5px;
    }
  }

  &-mbsp5 {
    @include sp {
      margin-bottom: 5px;
    }
  }

  &-mb10 {
    @include pc {
      margin-bottom: 10px;
    }
  }

  &-mbsp10 {
    @include sp {
      margin-bottom: 10px;
    }
  }

  &-mb15 {
    @include pc {
      margin-bottom: 15px;
    }
  }

  &-mbsp15 {
    @include sp {
      margin-bottom: 15px;
    }
  }

  &-mb20 {
    @include pc {
      margin-bottom: 20px;
    }
  }

  &-mbsp20 {
    @include sp {
      margin-bottom: 20px;
    }
  }

  &-mb25 {
    @include pc {
      margin-bottom: 25px;
    }
  }

  &-mbsp25 {
    @include sp {
      margin-bottom: 25px;
    }
  }

  &-mb30 {
    @include pc {
      margin-bottom: 30px;
    }
  }

  &-mbsp30 {
    @include sp {
      margin-bottom: 30px;
    }
  }

  &-mb35 {
    @include pc {
      margin-bottom: 35px;
    }
  }

  &-mbsp35 {
    @include sp {
      margin-bottom: 35px;
    }
  }

  &-mb40 {
    @include pc {
      margin-bottom: 40px;
    }
  }

  &-mbsp40 {
    @include sp {
      margin-bottom: 40px;
    }
  }

  &-mb45 {
    @include pc {
      margin-bottom: 45px;
    }
  }

  &-mbsp45 {
    @include sp {
      margin-bottom: 45px;
    }
  }

  &-mb50 {
    @include pc {
      margin-bottom: 50px;
    }
  }

  &-mbsp50 {
    @include sp {
      margin-bottom: 50px;
    }
  }

  &-mb55 {
    @include pc {
      margin-bottom: 55px;
    }
  }

  &-mbsp55 {
    @include sp {
      margin-bottom: 55px;
    }
  }

  &-mb60 {
    @include pc {
      margin-bottom: 60px;
    }
  }

  &-mbsp60 {
    @include sp {
      margin-bottom: 60px;
    }
  }

  &-mb65 {
    @include pc {
      margin-bottom: 65px;
    }
  }

  &-mbsp65 {
    @include sp {
      margin-bottom: 65px;
    }
  }

  &-mb70 {
    @include pc {
      margin-bottom: 70px;
    }
  }

  &-mbsp70 {
    @include sp {
      margin-bottom: 70px;
    }
  }

  &-mb75 {
    @include pc {
      margin-bottom: 75px;
    }
  }

  &-mbsp75 {
    @include sp {
      margin-bottom: 75px;
    }
  }

  &-mb80 {
    @include pc {
      margin-bottom: 80px;
    }
  }

  &-mbsp80 {
    @include sp {
      margin-bottom: 80px;
    }
  }

  &-mb85 {
    @include pc {
      margin-bottom: 85px;
    }
  }

  &-mbsp85 {
    @include sp {
      margin-bottom: 85px;
    }
  }

  &-mb90 {
    @include pc {
      margin-bottom: 90px;
    }
  }

  &-mbsp90 {
    @include sp {
      margin-bottom: 90px;
    }
  }

  &-mb95 {
    @include pc {
      margin-bottom: 95px;
    }
  }

  &-mbsp95 {
    @include sp {
      margin-bottom: 95px;
    }
  }

  &-mb100 {
    @include pc {
      margin-bottom: 100px;
    }
  }

  &-mbsp100 {
    @include sp {
      margin-bottom: 100px;
    }
  }

  &-pt5 {
    @include pc {
      padding-top: 5px;
    }
  }

  &-ptsp5 {
    @include sp {
      padding-top: 5px;
    }
  }

  &-pt10 {
    @include pc {
      padding-top: 10px;
    }
  }

  &-ptsp10 {
    @include sp {
      padding-top: 10px;
    }
  }

  &-pt15 {
    @include pc {
      padding-top: 15px;
    }
  }

  &-ptsp15 {
    @include sp {
      padding-top: 15px;
    }
  }

  &-pt20 {
    @include pc {
      padding-top: 20px;
    }
  }

  &-ptsp20 {
    @include sp {
      padding-top: 20px;
    }
  }

  &-pt25 {
    @include pc {
      padding-top: 25px;
    }
  }

  &-ptsp25 {
    @include sp {
      padding-top: 25px;
    }
  }

  &-pt30 {
    @include pc {
      padding-top: 30px;
    }
  }

  &-ptsp30 {
    @include sp {
      padding-top: 30px;
    }
  }

  &-pt35 {
    @include pc {
      padding-top: 35px;
    }
  }

  &-ptsp35 {
    @include sp {
      padding-top: 35px;
    }
  }

  &-pt40 {
    @include pc {
      padding-top: 40px;
    }
  }

  &-ptsp40 {
    @include sp {
      padding-top: 40px;
    }
  }

  &-pt45 {
    @include pc {
      padding-top: 45px;
    }
  }

  &-ptsp45 {
    @include sp {
      padding-top: 45px;
    }
  }

  &-pt50 {
    @include pc {
      padding-top: 50px;
    }
  }

  &-ptsp50 {
    @include sp {
      padding-top: 50px;
    }
  }

  &-pt55 {
    @include pc {
      padding-top: 55px;
    }
  }

  &-ptsp55 {
    @include sp {
      padding-top: 55px;
    }
  }

  &-pt60 {
    @include pc {
      padding-top: 60px;
    }
  }

  &-ptsp60 {
    @include sp {
      padding-top: 60px;
    }
  }

  &-pt65 {
    @include pc {
      padding-top: 65px;
    }
  }

  &-ptsp65 {
    @include sp {
      padding-top: 65px;
    }
  }

  &-pt70 {
    @include pc {
      padding-top: 70px;
    }
  }

  &-ptsp70 {
    @include sp {
      padding-top: 70px;
    }
  }

  &-pt75 {
    @include pc {
      padding-top: 75px;
    }
  }

  &-ptsp75 {
    @include sp {
      padding-top: 75px;
    }
  }

  &-pt80 {
    @include pc {
      padding-top: 80px;
    }
  }

  &-ptsp80 {
    @include sp {
      padding-top: 80px;
    }
  }

  &-pt85 {
    @include pc {
      padding-top: 85px;
    }
  }

  &-ptsp85 {
    @include sp {
      padding-top: 85px;
    }
  }

  &-pt90 {
    @include pc {
      padding-top: 90px;
    }
  }

  &-ptsp90 {
    @include sp {
      padding-top: 90px;
    }
  }

  &-pt95 {
    @include pc {
      padding-top: 95px;
    }
  }

  &-ptsp95 {
    @include sp {
      padding-top: 95px;
    }
  }

  &-pt100 {
    @include pc {
      padding-top: 100px;
    }
  }

  &-ptsp100 {
    @include sp {
      padding-top: 100px;
    }
  }

  &-pr5 {
    @include pc {
      padding-right: 5px;
    }
  }

  &-prsp5 {
    @include sp {
      padding-right: 5px;
    }
  }

  &-pr10 {
    @include pc {
      padding-right: 10px;
    }
  }

  &-prsp10 {
    @include sp {
      padding-right: 10px;
    }
  }

  &-pr15 {
    @include pc {
      padding-right: 15px;
    }
  }

  &-prsp15 {
    @include sp {
      padding-right: 15px;
    }
  }

  &-pr20 {
    @include pc {
      padding-right: 20px;
    }
  }

  &-prsp20 {
    @include sp {
      padding-right: 20px;
    }
  }

  &-pr25 {
    @include pc {
      padding-right: 25px;
    }
  }

  &-prsp25 {
    @include sp {
      padding-right: 25px;
    }
  }

  &-pr30 {
    @include pc {
      padding-right: 30px;
    }
  }

  &-prsp30 {
    @include sp {
      padding-right: 30px;
    }
  }

  &-pr35 {
    @include pc {
      padding-right: 35px;
    }
  }

  &-prsp35 {
    @include sp {
      padding-right: 35px;
    }
  }

  &-pr40 {
    @include pc {
      padding-right: 40px;
    }
  }

  &-prsp40 {
    @include sp {
      padding-right: 40px;
    }
  }

  &-pr45 {
    @include pc {
      padding-right: 45px;
    }
  }

  &-prsp45 {
    @include sp {
      padding-right: 45px;
    }
  }

  &-pr50 {
    @include pc {
      padding-right: 50px;
    }
  }

  &-prsp50 {
    @include sp {
      padding-right: 50px;
    }
  }

  &-pr55 {
    @include pc {
      padding-right: 55px;
    }
  }

  &-prsp55 {
    @include sp {
      padding-right: 55px;
    }
  }

  &-pr60 {
    @include pc {
      padding-right: 60px;
    }
  }

  &-prsp60 {
    @include sp {
      padding-right: 60px;
    }
  }

  &-pr65 {
    @include pc {
      padding-right: 65px;
    }
  }

  &-prsp65 {
    @include sp {
      padding-right: 65px;
    }
  }

  &-pr70 {
    @include pc {
      padding-right: 70px;
    }
  }

  &-prsp70 {
    @include sp {
      padding-right: 70px;
    }
  }

  &-pr75 {
    @include pc {
      padding-right: 75px;
    }
  }

  &-prsp75 {
    @include sp {
      padding-right: 75px;
    }
  }

  &-pr80 {
    @include pc {
      padding-right: 80px;
    }
  }

  &-prsp80 {
    @include sp {
      padding-right: 80px;
    }
  }

  &-pr85 {
    @include pc {
      padding-right: 85px;
    }
  }

  &-prsp85 {
    @include sp {
      padding-right: 85px;
    }
  }

  &-pr90 {
    @include pc {
      padding-right: 90px;
    }
  }

  &-prsp90 {
    @include sp {
      padding-right: 90px;
    }
  }

  &-pr95 {
    @include pc {
      padding-right: 95px;
    }
  }

  &-prsp95 {
    @include sp {
      padding-right: 95px;
    }
  }

  &-pr100 {
    @include pc {
      padding-right: 100px;
    }
  }

  &-prsp100 {
    @include sp {
      padding-right: 100px;
    }
  }

  &-pl5 {
    @include pc {
      padding-left: 5px;
    }
  }

  &-plsp5 {
    @include sp {
      padding-left: 5px;
    }
  }

  &-pl10 {
    @include pc {
      padding-left: 10px;
    }
  }

  &-plsp10 {
    @include sp {
      padding-left: 10px;
    }
  }

  &-pl15 {
    @include pc {
      padding-left: 15px;
    }
  }

  &-plsp15 {
    @include sp {
      padding-left: 15px;
    }
  }

  &-pl20 {
    @include pc {
      padding-left: 20px;
    }
  }

  &-plsp20 {
    @include sp {
      padding-left: 20px;
    }
  }

  &-pl25 {
    @include pc {
      padding-left: 25px;
    }
  }

  &-plsp25 {
    @include sp {
      padding-left: 25px;
    }
  }

  &-pl30 {
    @include pc {
      padding-left: 30px;
    }
  }

  &-plsp30 {
    @include sp {
      padding-left: 30px;
    }
  }

  &-pl35 {
    @include pc {
      padding-left: 35px;
    }
  }

  &-plsp35 {
    @include sp {
      padding-left: 35px;
    }
  }

  &-pl40 {
    @include pc {
      padding-left: 40px;
    }
  }

  &-plsp40 {
    @include sp {
      padding-left: 40px;
    }
  }

  &-pl45 {
    @include pc {
      padding-left: 45px;
    }
  }

  &-plsp45 {
    @include sp {
      padding-left: 45px;
    }
  }

  &-pl50 {
    @include pc {
      padding-left: 50px;
    }
  }

  &-plsp50 {
    @include sp {
      padding-left: 50px;
    }
  }

  &-pl55 {
    @include pc {
      padding-left: 55px;
    }
  }

  &-plsp55 {
    @include sp {
      padding-left: 55px;
    }
  }

  &-pl60 {
    @include pc {
      padding-left: 60px;
    }
  }

  &-plsp60 {
    @include sp {
      padding-left: 60px;
    }
  }

  &-pl65 {
    @include pc {
      padding-left: 65px;
    }
  }

  &-plsp65 {
    @include sp {
      padding-left: 65px;
    }
  }

  &-pl70 {
    @include pc {
      padding-left: 70px;
    }
  }

  &-plsp70 {
    @include sp {
      padding-left: 70px;
    }
  }

  &-pl75 {
    @include pc {
      padding-left: 75px;
    }
  }

  &-plsp75 {
    @include sp {
      padding-left: 75px;
    }
  }

  &-pl80 {
    @include pc {
      padding-left: 80px;
    }
  }

  &-plsp80 {
    @include sp {
      padding-left: 80px;
    }
  }

  &-pl85 {
    @include pc {
      padding-left: 85px;
    }
  }

  &-plsp85 {
    @include sp {
      padding-left: 85px;
    }
  }

  &-pl90 {
    @include pc {
      padding-left: 90px;
    }
  }

  &-plsp90 {
    @include sp {
      padding-left: 90px;
    }
  }

  &-pl95 {
    @include pc {
      padding-left: 95px;
    }
  }

  &-plsp95 {
    @include sp {
      padding-left: 95px;
    }
  }

  &-pl100 {
    @include pc {
      padding-left: 100px;
    }
  }

  &-plsp100 {
    @include sp {
      padding-left: 100px;
    }
  }

  &-pb5 {
    @include pc {
      padding-bottom: 5px;
    }
  }

  &-pbsp5 {
    @include sp {
      padding-bottom: 5px;
    }
  }

  &-pb10 {
    @include pc {
      padding-bottom: 10px;
    }
  }

  &-pbsp10 {
    @include sp {
      padding-bottom: 10px;
    }
  }

  &-pb15 {
    @include pc {
      padding-bottom: 15px;
    }
  }

  &-pbsp15 {
    @include sp {
      padding-bottom: 15px;
    }
  }

  &-pb20 {
    @include pc {
      padding-bottom: 20px;
    }
  }

  &-pbsp20 {
    @include sp {
      padding-bottom: 20px;
    }
  }

  &-pb25 {
    @include pc {
      padding-bottom: 25px;
    }
  }

  &-pbsp25 {
    @include sp {
      padding-bottom: 25px;
    }
  }

  &-pb30 {
    @include pc {
      padding-bottom: 30px;
    }
  }

  &-pbsp30 {
    @include sp {
      padding-bottom: 30px;
    }
  }

  &-pb35 {
    @include pc {
      padding-bottom: 35px;
    }
  }

  &-pbsp35 {
    @include sp {
      padding-bottom: 35px;
    }
  }

  &-pb40 {
    @include pc {
      padding-bottom: 40px;
    }
  }

  &-pbsp40 {
    @include sp {
      padding-bottom: 40px;
    }
  }

  &-pb45 {
    @include pc {
      padding-bottom: 45px;
    }
  }

  &-pbsp45 {
    @include sp {
      padding-bottom: 45px;
    }
  }

  &-pb50 {
    @include pc {
      padding-bottom: 50px;
    }
  }

  &-pbsp50 {
    @include sp {
      padding-bottom: 50px;
    }
  }

  &-pb55 {
    @include pc {
      padding-bottom: 55px;
    }
  }

  &-pbsp55 {
    @include sp {
      padding-bottom: 55px;
    }
  }

  &-pb60 {
    @include pc {
      padding-bottom: 60px;
    }
  }

  &-pbsp60 {
    @include sp {
      padding-bottom: 60px;
    }
  }

  &-pb65 {
    @include pc {
      padding-bottom: 65px;
    }
  }

  &-pbsp65 {
    @include sp {
      padding-bottom: 65px;
    }
  }

  &-pb70 {
    @include pc {
      padding-bottom: 70px;
    }
  }

  &-pbsp70 {
    @include sp {
      padding-bottom: 70px;
    }
  }

  &-pb75 {
    @include pc {
      padding-bottom: 75px;
    }
  }

  &-pbsp75 {
    @include sp {
      padding-bottom: 75px;
    }
  }

  &-pb80 {
    @include pc {
      padding-bottom: 80px;
    }
  }

  &-pbsp80 {
    @include sp {
      padding-bottom: 80px;
    }
  }

  &-pb85 {
    @include pc {
      padding-bottom: 85px;
    }
  }

  &-pbsp85 {
    @include sp {
      padding-bottom: 85px;
    }
  }

  &-pb90 {
    @include pc {
      padding-bottom: 90px;
    }
  }

  &-pbsp90 {
    @include sp {
      padding-bottom: 90px;
    }
  }

  &-pb95 {
    @include pc {
      padding-bottom: 95px;
    }
  }

  &-pbsp95 {
    @include sp {
      padding-bottom: 95px;
    }
  }

  &-pb100 {
    @include pc {
      padding-bottom: 100px;
    }
  }

  &-pbsp100 {
    @include sp {
      padding-bottom: 100px;
    }
  }
}