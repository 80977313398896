// footer
.footer{
  position: relative;
 &::before{
  content: '';
  background: url(/common/images/bg_footer_pc.png) no-repeat;
  background-position: bottom;
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
 }

 @include sp{
  &::before{
    top: auto;
    bottom: 0;
    background-size: contain;
   }
 }
 .pageTop{
  position: fixed;
  z-index: 49;
  right: 7.1%;
  bottom: 40px;
  background: url(/common/images/pageTop_back.svg) no-repeat;
  width: 50px;
  height: 50px;

  @include sp{
    right: 5.3%;
  }

    a{
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      &::before{
        position: absolute;
        content: '';
        background: url(/common/images/icon_arrowWhite.svg) no-repeat;
        transform: rotate(-90deg);
        width: 25px;
        height: 25px;
        top: 11px;
        left: 15px;
      }
    }
  }
 .footerWrap{
  position: relative;
  max-width: 1260px;
  margin: 0 auto 0;
  padding: 0 30px 38%;
  @include flex;
  @include alignItems(center);
  @include justifyContent(sb);
  gap: 50px;

  @include tab{
		flex-direction: column-reverse;
		gap: 30px
	}

  @include sp{
  //  width: 89.33%;
   @include flexDirection(col);
   gap: 0px;
   padding: 0 0 150px 0;
  }

  .footerInfo{
   @include flex;
   @include alignItems(center);
   @include flexDirection(col);
   margin-right: 30px;

   @include sp{
    width: 100%;
    order: 2;
    margin: 5px auto 0px;
   }

   &Kurumin{
    width: 90px;
    margin-bottom: 20px;
   }

   p{
    font-size: 1.3rem;
    margin-bottom: 80px;
    text-align: center;

    @include tab{
      margin-bottom: 50px;
    }

    @include sp{
     margin-bottom: 45px;
    }
   }

   &Nkids{
    display: block;
    width: 220px;
    height: 48px;
    margin-bottom: 80px;
    transition: .3s;

    &:hover{
     opacity: .6;
    }

    @include tab{
      margin-bottom: 60px;
    }
    @include sp{
     margin-bottom: 45px
    }
   }

   &Link{
    @include flex;
    @include alignItems(center);
    // @include justifyContent(sb);
    width: 330px;
    margin-bottom: 40px;

    @include sp{
     margin-bottom: 30px;
    }

    &List{
     border-right: 1px solid #c6c6c6;
     padding-right: 80px;

     li{
      line-height: 1;
      margin-top: 16px;
      font-size: 1.3rem;

      @include sp{
       margin-top: 25px;
      }

      &:first-of-type{
       margin-top: 0px;
      }

      a{
       transition: .3s;
       text-decoration: none;
       color: #323232;
       &:hover{
        opacity: .6;
       }
      }
     }
    }
    &Insta{
     padding-left: 30px;

     a{
      transition: .3s;
      &:hover{
       opacity: .6;
      }
     }
    }
   }

   &Copyright{
    font-size: 1.2rem;
    padding-top: 15px;

    @include sp{
     position: relative;
     width: 100%;
     text-align: center;
     &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid #e0e0e0;
     }
    }
   }
  }

  .footerNav{
    max-width: 820px;
    width: 100%;
    position: relative;
    @include flex;
    @include alignItems(start);
    gap: 40px;

    @include sp{
      width: 89.33%;
      order: 1;
      @include flexDirection(col);
      @include alignItems(stretch);
      gap: 0;
    }

    > ul{
      width: 100%;
      max-width: 240px;

      @include sp{
        max-width: 100%;
      }
    }

   &Parent,
   &Aco{
    display: block;
    position: relative;
    margin-top: 60px;
    border-bottom: 1px solid rgba($pink , .4);
    font-weight: $bold;
    @media (min-width: 750px) and (max-width: 1200px) {
      font-size: 1.2vw;
    }



    @include sp{
      font-size: 1.4rem;
      margin-top: 0;
      border-top: 1px solid rgba($pink , .4);
      border-bottom: none;
      padding: 25px 0;
    }

    &:nth-of-type(1){
     margin-top: 0px;
    }

    // フォントサイズ調整
    a,
    span{
      display: block;
      font-size: 1.6rem;
      @media (min-width: 750px) and (max-width: 1200px) {
        font-size: 1.15vw;
      }
      @include tab{
        font-size: 1.5rem;
      }
    }

   }

   &Child{
    padding-left: 12px;
   }

   // ボタン装飾
   &Parent{
    position: relative;
    @include sp{

     &::before{
      content: '';
      position: absolute;
      top: 34px;
      right: 0px;
      width: 10px;
      height: 10px;
      border-right: 2px solid #323232;
      border-top: 2px solid #323232;
      transform: rotate(45deg);
      border-radius: 0.05em;
     }
     &::after{
      content: '';
      position: absolute;
      top: 38px;
      right: 0px;
      width: 15px;
      background: #333;
      height: 2px;
      border-radius: 0.05em;
     }
    }

    a{
     transition: .3s;
     text-decoration: none;
     color: #323232;
     &:hover{
      opacity: .6;
     }
    }
    &.smlBtn-external{
      display: block;
      padding-bottom: 0px;
      
      a{
        line-height: 1.75;
        font-size: 1.6rem;
        width: 100%;
        &::after{
          right: 5px;
          width: 15px;
          height: 15px;

          @include sp{
            right: 0px;
            width: 15px;
            height: 15px;
          }
        }
        &:hover::after{
          background: url(/common/images/icon_externalBlack.svg) no-repeat;
        }
      }
      @include sp{
        padding-bottom: 25px;
        &::after,
        &::before{
          content: none;
        }
      }
    }
    &.bdLast{
      @include sp{
        border-bottom: 1px solid rgba($pink , .4);
      }
      &.open{
        @include sp{
          border-bottom: 0px solid rgba($pink , .4);
        }
      }
    }
   }

   // Sp時アコーディオン
   &Aco{
    pointer-events: none;
    @include sp{
     pointer-events: all;
     cursor: pointer;
    //  padding: 24px 0;

     &::before,
     &::after{
      content: "";
      position: absolute;
      top: 38px;
      right: 0px;
      width: 17px;
      height: 2px;
      border-top: 2px solid #323232;
      border-radius: 0.05em;
      transition: .3s;
     }
     &::after{
      -webkit-transform: rotate(90deg);
	     transform: rotate(90deg);
     }
    }
    &.open::before{
      opacity: 0;

     }
     &.open::after{
      transform: rotate(180deg);
      transition: .3s;
     }
   }

   &Child{
    @include sp{
     border-top: 1px solid rgba($pink , .4);
     display: none;
    }
    li{
     font-size: 1.3rem;
     margin-top: 18px;
     position: relative;
     padding-left: 10px;
     @include flex;
     // @include alignItems(center);

     &::after{
      content: '';
      position: absolute;
      display: block;
      top: 9px;
      left: 0;
      width: 4px;
      height: 4px;
      background-color: $pink;
      border-radius: 50%;
      margin-right: 10px;
     }
    } 

    @include sp{
     margin-bottom: 25px;
    }

    a{
     transition: .3s;
     text-decoration: none;
      color: #323232;
     &:hover{
      opacity: .6;
     }
    }
   }
  }
 }
}