.sec {
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-right: auto;
  margin-left: auto;

  @include sp {
    padding-right: 5.3%;
    padding-left: 5.3%;
  }

  &.full {
    max-width: 100%;
    padding: 0;
  }

  &.max1000 {
    max-width: 1080px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;

    @include sp {
      padding-right: 5.3%;
      padding-left: 5.3%;
    }
  }
}

// ショルダーコピー
.sdCopy {
  font-size: 2.2rem;
  font-family: $font-zenmaru;
  line-height: 2.3;
  font-weight: 500;
  @include sp {
    font-size: 2rem;
    line-height: 1.75;
  }
}

// 注意文
.attention {
  color: #c81428;
  font-size: 12px;
  margin-bottom: 1em;

  &.kome {
    position: relative;
    // text-indent: -1em;
    padding-left: 1em;

    &::after {
      content: '※';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

// 補足文
.supplement {
  font-size: 12px;

  &.kome {
    position: relative;
    // text-indent: -1em;
    padding-left: 1em;

    &::after {
      content: '※';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.frame {
  &Green {
    border-radius: 18px;
    background: url(/common/images/bg_frameGreen.png)repeat top left/cover;
    padding: 5%;

    @include sp {
      padding: 8.95%;
    }
  }

  &Blue {
    border-radius: 18px;
    background: url(/common/images/bg_frameBlue.png)repeat top left/cover;
    padding: 5%;

    @include sp {
      padding: 8.95%;
    }
  }

  &Pink {
    border-radius: 18px;
    background: url(/common/images/bg_framePink.png)repeat top left/cover;
    padding: 5%;

    @include sp {
      padding: 8.95%;
    }
  }

  &Orange {
    border-radius: 18px;
    background: url(/common/images/bg_frameOrange.png)repeat top left/cover;
    padding: 5%;

    @include sp {
      padding: 8.95%;
    }
  }

  &Gray {
    border-radius: 18px;
    background: url(/common/images/bg_frameGray.png)repeat top left/cover;
    padding: 5%;

    @include sp {
      padding: 8.95%;
    }
  }
}

// 大ボタン
.lgBtn {
  a {
    position: relative;
    display: inline-block;
    background-color: #fff;
    color: $blue;
    border: 1px solid $blue;
    border-radius: 30px;
    line-height: 1;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 22px 120px;
    transition: .3s;
    text-decoration: none;

    @include sp {
      font-size: 16px;
      padding: 18px 16%;
      width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      background: url(/common/images/icon_arrowBlue.svg) no-repeat;
      width: 16px;
      height: 13px;
      top: 50%;
      right: 7.5%;
      transform: translateY(-50%);
      transition: .3s;
    }

    &:hover {
      &::after {
        background: url(/common/images/icon_arrowWhite.svg) no-repeat;
      }

      background-color: $blue;
      color: #fff;
    }
  }

  // 戻るボタン
  &-back {
    @extend .lgBtn;

    a {
      &::after {
        transform: translateY(-50%) rotate(180deg);
        left: 7.5%;
        right: unset;
      }
    }
  }

  // pdfボタン
  a[href$=".pdf"] {
    &::after {
      content: '';
      position: absolute;
      background: url(/common/images/icon_pdfBlue.svg) no-repeat;
      width: 23px;
      height: 30px;

      @include sp {
        width: 17px;
        height: 23px;
      }
    }

    &:hover {
      &::after {
        background: url(/common/images/icon_pdfWhite.svg) no-repeat;
      }
    }
  }

  // 外部リンクボタン
  &-external {
    @extend .lgBtn;

    a {
      &::after {
        content: '';
        position: absolute;
        background: url(/common/images/icon_externalBlue.svg) no-repeat;
        width: 30px;
        height: 30px;

        @include sp {
          width: 23px;
          height: 23px;
        }
      }

      &:hover {
        &::after {
          background: url(/common/images/icon_externalWhite.svg) no-repeat;
        }
      }
    }
    &.on {
      a {
        background-color: $blue;
        color: #fff;
        &::after{
          background: url(/common/images/icon_externalWhite.svg) no-repeat;
        }
          &:hover {
          background: #fff;
          color: $blue;
          &::after{
            background: url(/common/images/icon_externalBlue.svg) no-repeat;
          }
        }
      }
    }
  }

  // 複数行ボタン
  &.multiRow {
    a {
      line-height: 1.889;
    }
  }

  // 非リンクボタン
  &.inactive {
    a {
      pointer-events: none;
      color: #a8a8a8;
      border: 1px solid #a8a8a8;

      &::after {
        background: url(/common/images/icon_arrowGray.svg) no-repeat;
      }
    }
  }

  // ボタンバリエーション
  &Green {
    @extend .lgBtn;

    a {
      color: $green;
      border: 1px solid $green;

      &::after {
        background: url(/common/images/icon_arrowGreen.svg) no-repeat;
      }

      &:hover {
        background-color: $green;
      }
    }

    &-back {
      @extend .lgBtn-back;

      a {
        color: $green;
        border: 1px solid $green;

        &::after {
          background: url(/common/images/icon_arrowGreen.svg) no-repeat;
        }

        &:hover {
          background-color: $green;
        }
      }
      &.on {
        a {
          background-color: $green;
          color: #fff;
          &::after{
            background: url(/common/images/icon_arrowWhite.svg) no-repeat;          }
          &:hover {
            background: #fff;
            color: $green;
            &::after{
              background: url(/common/images/icon_arrowGreen.svg) no-repeat;
            }
          }
        }
      }
    }

    a[href$=".pdf"] {
      &::after {
        background: url(/common/images/icon_pdfGreen.svg) no-repeat;
      }
    }

    &-external {
      @extend .lgBtn-external;

      a {
        color: $green;
        border: 1px solid $green;

        &::after {
          background: url(/common/images/icon_externalGreen.svg) no-repeat;
        }

        &:hover {
          background-color: $green;
        }
      }
      &.on {
        a {
          background-color: $green;
          color: #fff;
          &::after{
            background: url(/common/images/icon_externalWhite.svg) no-repeat;
          }
          &:hover {
            background: #fff;
            color: $green;
            &::after{
              background: url(/common/images/icon_externalGreen.svg) no-repeat;
            }
          }
        }
      }
    }
  }

  &Orange {
    @extend .lgBtn;

    a {
      color: $orange;
      border: 1px solid $orange;

      &::after {
        background: url(/common/images/icon_arrowOrange.svg) no-repeat;
      }

      &:hover {
        background-color: $orange;
      }
    }

    &-back {
      @extend .lgBtn-back;

      a {
        color: $orange;
        border: 1px solid $orange;

        &::after {
          background: url(/common/images/icon_arrowOrange.svg) no-repeat;
        }

        &:hover {
          background-color: $orange;
        }
      }
      &.on {
        a {
          background-color: $orange;
          color: #fff;
          &::after{
            background: url(/common/images/icon_arrowWhite.svg) no-repeat;          }
          &:hover {
            background: #fff;
            color: $orange;
            &::after{
              background: url(/common/images/icon_arrowOrange.svg) no-repeat;
            }
          }
        }
      }
    }

    a[href$=".pdf"] {
      &::after {
        background: url(/common/images/icon_pdfOrange.svg) no-repeat;
      }
    }

    &-external {
      @extend .lgBtn-external;

      a {
        color: $orange;
        border: 1px solid $orange;

        &::after {
          background: url(/common/images/icon_externalOrange.svg) no-repeat;
        }

        &:hover {
          background-color: $orange;
        }
      }
      &.on {
        a {
          background-color: $orange;
          color: #fff;
          &::after{
            background: url(/common/images/icon_externalWhite.svg) no-repeat;
          }
          &:hover {
            background: #fff;
            color: $orange;
            &::after{
              background: url(/common/images/icon_externalOrange.svg) no-repeat;
            }
          }
        }
      }
    }
  }

  &Pink {
    @extend .lgBtn;

    a {
      color: $pink;
      border: 1px solid $pink;

      &::after {
        background: url(/common/images/icon_arrowPink.svg) no-repeat;
      }

      &:hover {
        background-color: $pink;
      }
    }

    &-back {
      @extend .lgBtn-back;

      a {
        color: $pink;
        border: 1px solid $pink;

        &::after {
          background: url(/common/images/icon_arrowPink.svg) no-repeat;
        }

        &:hover {
          background-color: $pink;
        }
      }
      &.on {
        a {
          background-color: $pink;
          color: #fff;
          &::after{
            background: url(/common/images/icon_arrowWhite.svg) no-repeat;          }
          &:hover {
            background: #fff;
            color: $pink;
            &::after{
              background: url(/common/images/icon_arrowPink.svg) no-repeat;
            }
          }
        }
      }
    }

    a[href$=".pdf"] {
      &::after {
        background: url(/common/images/icon_pdfPink.svg) no-repeat;
      }
    }

    &-external {
      @extend .lgBtn-external;

      a {
        color: $pink;
        border: 1px solid $pink;

        &::after {
          background: url(/common/images/icon_externalPink.svg) no-repeat;
        }

        &:hover {
          background-color: $pink;
        }
      }
      &.on {
        a {
          background-color: $pink;
          color: #fff;
          &::after{
            background: url(/common/images/icon_externalWhite.svg) no-repeat;
          }
          &:hover {
            background: #fff;
            color: $pink;
            &::after{
              background: url(/common/images/icon_externalPink.svg) no-repeat;
            }
          }
        }
      }
    }
  }

  &Purple {
    @extend .lgBtn;

    a {
      color: $purple;
      border: 1px solid $purple;

      &::after {
        background: url(/common/images/icon_arrowPurple.svg) no-repeat;
      }

      &:hover {
        background-color: $purple;
      }
    }

    &-back {
      @extend .lgBtn-back;

      a {
        color: $purple;
        border: 1px solid $purple;

        &::after {
          background: url(/common/images/icon_arrowPurple.svg) no-repeat;
        }

        &:hover {
          background-color: $purple;
        }
      }
      &.on {
        a {
          background-color: $purple;
          color: #fff;
          &::after{
            background: url(/common/images/icon_arrowWhite.svg) no-repeat;          }
          &:hover {
            background: #fff;
            color: $purple;
            &::after{
              background: url(/common/images/icon_arrowPurple.svg) no-repeat;
            }
          }
        }
      }
    }

    a[href$=".pdf"] {
      &::after {
        background: url(/common/images/icon_pdfPurple.svg) no-repeat;
      }
    }

    &-external {
      @extend .lgBtn-external;

      a {
        color: $purple;
        border: 1px solid $purple;

        &::after {
          background: url(/common/images/icon_externalPurple.svg) no-repeat;
        }

        &:hover {
          background-color: $purple;
        }
      }
      &.on {
        a {
          background-color: $purple;
          color: #fff;
          &::after{
            background: url(/common/images/icon_externalWhite.svg) no-repeat;
          }
          &:hover {
            background: #fff;
            color: $purple;
            &::after{
              background: url(/common/images/icon_externalPurple.svg) no-repeat;
            }
          }
        }
      }
    }
  }
}

// 中ボタン
.midBtn {
  @extend .lgBtn;

  a {
    color: #323232;
    background-color: #fff;
    min-width: 200px;
    font-size: 14px;
    padding: 20px 3.28em;

    @include sp {
      min-width: 220px;
      width: auto;
      padding: 13px 3.28em;
    }

    &::after {
      content: '';
      position: absolute;
      background: url(/common/images/icon_arrowBlack.svg) no-repeat;
      width: 14px;
      height: 12px;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 15px;
      transition: .3s;
    }

    &:hover {
      background-color: $blue;
      color: #fff;

      &::after {
        background: url(/common/images/icon_arrowWhite.svg) no-repeat;
      }
    }
  }

  // 戻るボタン
  &-back {
    @extend .midBtn;

    a {
      &::after {
        transform: translateY(-50%) rotate(180deg);
        left: 0;
        margin-left: 18px;
      }
    }
  }

  // pdfボタン
  a[href$=".pdf"] {
    &::after {
      content: '';
      position: absolute;
      background: url(/common/images/icon_pdfBlack.svg) no-repeat;
      width: 17px;
      height: 20px;
      margin-left: 16px;

      @include sp {
        width: 11.5px;
        height: 15px;
        margin-left: 20px;
      }
    }

    &:hover {
      &::after {
        background: url(/common/images/icon_pdfWhite.svg) no-repeat;
      }
    }
  }

  // 外部リンク
  &-external {
    @extend .midBtn;

    a {
      &::after {
        background: url(/common/images/icon_externalBlack.svg) no-repeat;
        width: 18px;
        height: 18px;
        margin-left: 14px;

        @include sp {
          width: 15px;
          height: 15px;
          margin-left: 17px;
        }
      }

      &:hover {
        &::after {
          background: url(/common/images/icon_externalWhite.svg) no-repeat;
        }
      }
    }
  }

  // 非リンクボタン
  &.inactive {
    a {
      pointer-events: none;
      color: #a8a8a8;
      border: 1px solid #a8a8a8;

      &::after {
        background: url(/common/images/icon_arrowGray.svg) no-repeat;
      }
    }
  }

  // カラーバリエーション
  &Green {
    @extend .midBtn;

    a {
      border: 1px solid $green;

      &:hover {
        background-color: $green;
      }
    }

    &-back {
      @extend .midBtn-back;

      a {
        border: 1px solid $green;

        &:hover {
          background-color: $green;
        }
      }
    }

    &-external {
      @extend .midBtn-external;

      a {
        border: 1px solid $green;

        &:hover {
          background-color: $green;
        }
      }
    }
  }

  &Orange {
    @extend .midBtn;

    a {
      border: 1px solid $orange;

      &:hover {
        background-color: $orange;
      }
    }

    &-back {
      @extend .midBtn-back;

      a {
        border: 1px solid $orange;

        &:hover {
          background-color: $orange;
        }
      }
    }

    &-external {
      @extend .midBtn-external;

      a {
        border: 1px solid $orange;

        &:hover {
          background-color: $orange;
        }
      }
    }
  }

  &Orange {
    @extend .midBtn;

    a {
      border: 1px solid $orange;

      &:hover {
        background-color: $orange;
      }
    }

    &-back {
      @extend .midBtn-back;

      a {
        border: 1px solid $orange;

        &:hover {
          background-color: $orange;
        }
      }
    }

    &-external {
      @extend .midBtn-external;

      a {
        border: 1px solid $orange;

        &:hover {
          background-color: $orange;
        }
      }
    }
  }

  &Pink {
    @extend .midBtn;

    a {
      border: 1px solid $pink;

      &:hover {
        background-color: $pink;
      }
    }

    &-back {
      @extend .midBtn-back;

      a {
        border: 1px solid $pink;

        &:hover {
          background-color: $pink;
        }
      }
    }

    &-external {
      @extend .midBtn-external;

      a {
        border: 1px solid $pink;

        &:hover {
          background-color: $pink;
        }
      }
    }
  }

  &Purple {
    @extend .midBtn;

    a {
      border: 1px solid $purple;

      &:hover {
        background-color: $purple;
      }
    }

    &-back {
      @extend .midBtn-back;

      a {
        border: 1px solid $purple;

        &:hover {
          background-color: $purple;
        }
      }
    }

    &-external {
      @extend .midBtn-external;

      a {
        border: 1px solid $purple;

        &:hover {
          background-color: $purple;
        }
      }
    }
  }
}

// 小ボタン
.smlBtn {
  @extend .midBtn;
  display: inline-block;
  transition: .3s;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba($blue, 0);

  &:hover {
    border-bottom: 1px solid rgba($blue, 1);
  }

  a {
    min-width: 0px;
    border: none;
    padding: 0px 1.68em 0px 0px;
    text-align: left;
    @include sp {
      font-size: 16px;
    }

    &::after {
      top: 50%;
      right: 0;
      margin-left: 0px
    }

    &:hover {
      background-color: #fff;
      color: $blue;

      &::after {
        background: url(/common/images/icon_arrowBlue.svg) no-repeat;
      }
    }
  }

  // 戻るボタン
  &-back {
    @extend .smlBtn;

    a {
      padding-right: 0px;
      padding-left: 1.68em;

      &::after {
        transform: translateY(-50%) rotate(180deg);
        left: 0;
        margin-left: 0px;
      }
    }
  }

  // pdfボタン
  a[href$=".pdf"] {
    padding-right: 2.12em;

    &::after {
      content: '';
      width: 21px;
      height: 26px;

      // margin-left: 0px;
      @include sp {
        width: 15px;
        height: 18px;
      }
    }

    &:hover {
      &::after {
        background: url(/common/images/icon_pdfBlue.svg) no-repeat;
      }
    }
  }

  // 外部リンク
  &-external {
    @extend .smlBtn;

    a {
      padding-right: 2.31em;

      &::after {
        background: url(/common/images/icon_externalBlack.svg) no-repeat;
        width: 26px;
        height: 26px;
        margin-left: 0px;

        @include sp {
          width: 18px;
          height: 18px;
        }
      }

      &:hover {
        &::after {
          background: url(/common/images/icon_externalBlue.svg) no-repeat;
        }
      }
    }
  }

  // 非リンクボタン
  &.inactive {
    border-bottom: none;

    a {
      pointer-events: none;
      color: #a8a8a8;
      border: none;

      &::after {
        background: url(/common/images/icon_arrowGray.svg) no-repeat;
      }
    }
  }

  // カラーバリエーション
  &Green {
    @extend .smlBtn;

    &:hover {
      border-bottom: 1px solid rgba($green, 1);
    }

    a {
      &:hover {
        color: $green;

        &::after {
          background: url(/common/images/icon_arrowGreen.svg) no-repeat;
        }
      }
    }

    &-back {
      @extend .smlBtn-back;

      &:hover {
        border-bottom: 1px solid rgba($green, 1);
      }

      a {
        &:hover {
          color: $green;

          &::after {
            background: url(/common/images/icon_arrowGreen.svg) no-repeat;
          }
        }
      }
    }

    a[href$=".pdf"] {
      &:hover {
        &::after {
          background: url(/common/images/icon_pdfGreen.svg) no-repeat;
        }
      }
    }

    &-external {
      @extend .smlBtn-external;

      &:hover {
        border-bottom: 1px solid rgba($green, 1);
      }

      a {
        &:hover {
          color: $green;

          &::after {
            background: url(/common/images/icon_externalGreen.svg) no-repeat;
          }
        }
      }
    }
  }

  &Orange {
    @extend .smlBtn;

    &:hover {
      border-bottom: 1px solid rgba($orange, 1);
    }

    a {
      &:hover {
        color: $orange;

        &::after {
          background: url(/common/images/icon_arrowOrange.svg) no-repeat;
        }
      }
    }

    &-back {
      @extend .smlBtn-back;

      &:hover {
        border-bottom: 1px solid rgba($orange, 1);
      }

      a {
        &:hover {
          color: $orange;

          &::after {
            background: url(/common/images/icon_arrowOrange.svg) no-repeat;
          }
        }
      }
    }

    a[href$=".pdf"] {
      &:hover {
        &::after {
          background: url(/common/images/icon_pdfOrange.svg) no-repeat;
        }
      }
    }

    &-external {
      @extend .smlBtn-external;

      &:hover {
        border-bottom: 1px solid rgba($orange, 1);
      }

      a {
        &:hover {
          color: $orange;

          &::after {
            background: url(/common/images/icon_externalOrange.svg) no-repeat;
          }
        }
      }
    }
  }

  &Pink {
    @extend .smlBtn;

    &:hover {
      border-bottom: 1px solid rgba($pink, 1);
    }

    a {
      &:hover {
        color: $pink;

        &::after {
          background: url(/common/images/icon_arrowPink.svg) no-repeat;
        }
      }
    }

    &-back {
      @extend .smlBtn-back;

      &:hover {
        border-bottom: 1px solid rgba($pink, 1);
      }

      a {
        &:hover {
          color: $pink;

          &::after {
            background: url(/common/images/icon_arrowPink.svg) no-repeat;
          }
        }
      }
    }

    a[href$=".pdf"] {
      &:hover {
        &::after {
          background: url(/common/images/icon_pdfPink.svg) no-repeat;
        }
      }
    }

    &-external {
      @extend .smlBtn-external;

      &:hover {
        border-bottom: 1px solid rgba($pink, 1);
      }

      a {
        &:hover {
          color: $pink;

          &::after {
            background: url(/common/images/icon_externalPink.svg) no-repeat;
          }
        }
      }
    }
  }

  &Purple {
    @extend .smlBtn;

    &:hover {
      border-bottom: 1px solid rgba($purple, 1);
    }

    a {
      &:hover {
        color: $purple;

        &::after {
          background: url(/common/images/icon_arrowPurple.svg) no-repeat;
        }
      }
    }

    &-back {
      @extend .smlBtn-back;

      &:hover {
        border-bottom: 1px solid rgba($purple, 1);
      }

      a {
        &:hover {
          color: $purple;

          &::after {
            background: url(/common/images/icon_arrowPurple.svg) no-repeat;
          }
        }
      }
    }

    a[href$=".pdf"] {
      &:hover {
        &::after {
          background: url(/common/images/icon_pdfPurple.svg) no-repeat;
        }
      }
    }

    &-external {
      @extend .smlBtn-external;

      &:hover {
        border-bottom: 1px solid rgba($purple, 1);
      }

      a {
        &:hover {
          color: $purple;

          &::after {
            background: url(/common/images/icon_externalPurple.svg) no-repeat;
          }
        }
      }
    }
  }
}

// アンカーリンク
.ancList {
  @include flex;
  @include flexWrap(wrap);
  @include justifyContent(center);
  gap: 60px 3.75em;

  @include sp {
    @include justifyContent(sb);
    gap: 30px 3.2%;
  }

  li {
    transition: .3s;

    a {
      border-bottom: 1px solid rgba(#fff, 0);
      color: #323232;
      text-decoration: none;
      line-height: 1;
      transition: .3s;
      @include pc{
        @include flex;
        @include alignItems(center);
      }
      &::after {
        content: '';
        width: 1em;
        height: 1em;
        display: inline-block;
        margin-left: 0.5em;
        background: url(/common/images/icon_udArrowBlue.svg) no-repeat center center;
      }

      &:hover {
        border-bottom: 1px solid rgba($blue, 1);
        color: $blue;
      }
    }
  }

}

// リスト
%ulList {
  margin-bottom: 1em;


  li {
    padding-left: 1.5em;
    margin-bottom: 10px;
    position: relative;

    @include sp {
      margin-bottom: 1.4%;
    }

    &::before {
      content: "・";
      position: absolute;
      top: 0;
      left: 0;
    }

    &>ol {
      @extend %olList;

      li {
        &::before {
          font-weight: normal;
          transform: scale(1);
        }
      }
    }

    .olList {
      @extend %olList;

      li {
        &::before {
          font-weight: normal;
          transform: scale(1);
        }
      }

    }


  }

  &:last-child {
    margin-bottom: 0;
  }

}

.ulList {
  @extend %ulList;
}

%olList {
  margin-bottom: 1em;

  &>li {
    counter-increment: olList;
    margin-bottom: 10px;
    padding-left: 1.5em;
    position: relative;

    @include sp {
      margin-bottom: 1.4%;
    }

    &::before {
      content: counter(olList)".";
      position: absolute;
      top: 0;
      left: 0;
    }

    .ulList {
      @extend %ulList;
    }

  }

  &:last-child {
    margin-bottom: 0;
  }
}

.olList {
  @extend %olList;
}

.relatedArticle{

  &.topicsRelatedArticle {
    ul {
      gap: 3.3%;

      li {
        width: 23%;
        margin-right: 0;
        @include sp {
          width: 46.2%;
        }
      }
    }
    .topicsRelatedTitle {
      font-weight: 400;
    }
    .topicsRelatedImage { 
      width: 100%;
      // height: 200px;
      @media  screen and (min-width: 749px) and (max-width: 1230px) {
        width: 100%;
        height: 15vw;
      }
      @include sp {
        width: 100%;
        height: 32vw;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }

}

.mainContents {

  // ショルダーコピー
  .sdTitle{
    font-size: 4.8rem;
    @include sp{
      font-size: 3.5rem;
    }
  }
  .sdCopy {
    font-size: 2.2rem;
    font-family: $font-zenmaru;
    line-height: 2.3;

    @include sp {
      font-size: 2rem;
      line-height: 1.75;
    }
  }

  // 注意文
  .attention {
    color: #c81428;
    font-size: 12px;
    margin-bottom: 1em;

    &.kome {
      position: relative;
      // text-indent: -1em;
      padding-left: 1em;

      &::after {
        content: '※';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  // 補足文
  .supplement {
    font-size: 12px;

    &.kome {
      position: relative;
      // text-indent: -1em;
      padding-left: 1em;

      &::after {
        content: '※';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}