//setting
@import "_reset.scss";
@import "_mq.scss";
@import "_var.scss";
@import "_mixin.scss";
@import "_module.scss";

// module

// parts
@import "_parts.scss";

// layout
@import "_footer.scss";
@import "_header.scss";

body{
  font-family: $noto-sans;
  color: #323232;
  font-size: 1.6rem;
  line-height: 1.75;
}

.pcOnly{
  @include sp{
    display: none; 
  }
}

.spOnly{
  @include pc{
    display: none;
  }
}

