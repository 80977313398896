// デフォルトパーツ
@import "_parts_common.scss";
@import "_parts_breadcrumb.scss";
@import "_parts_slider.scss";
@import "_parts_checkBox.scss"; 
@import "_parts_search.scss";
@import "_parts_pager.scss";
@import "_parts_imgBox.scss";
@import "_parts_colBox.scss";
@import "_parts_table.scss";
@import "_parts_tabContents.scss";
@import "_parts_migration.scss";
@import "_parts_accordion.scss";
@import "_parts_modal.scss";
@import "_parts_animation.scss";

h1 {
  font-size: 3.2rem;
  font-family: $font-zenmaru;
  line-height: 1.5;
  padding: 16px 0;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  text-align: center;
  @include sp {
    font-size: 2.4rem;
    line-height: 1.45;
    padding: 16px 5.3%;
  }
}

.mainContents {

  p {
    font-size: 1.6rem;
    margin-bottom: 1em;

    &+p {
      margin-top: 2em;
    }
  }

  h2 {
    font-size: 3.4rem;
    font-family: $font-zenmaru;
    line-height: 1.5;
    margin-bottom: 1em;
    text-align: center;

    @include sp {
      font-size: 2.5rem;
      line-height: 1.44;
    }
  }

  h3 {
    font-size: 2.4rem;
    font-family: $font-zenmaru;
    line-height: 1.5;
    margin-bottom: 1em;

    @include sp {
      font-size: 2.2rem;
    }
  }

  h4 {
    font-size: 1.8rem;
    font-family: $font-zenmaru;
    line-height: 1.8;
    margin-bottom: 1em;

    @include sp {
      line-height: 1.66;
    }
  }

  h5 {
    font-size: 1.6rem;
    font-family: $font-zenmaru;
    line-height: 2;
    margin-bottom: 1em;

    @include sp {
      line-height: 1.8;
    }
  }

  a{
    figure.img{
      overflow: hidden;
      img{
        transform: scale(1);
        transition: transform 0.3s;
      }
    }
    &:hover{
      figure.img{
        img{
          @include pc{
            transform: scale(1.1);
          }
        }
      }
    }
  }
}