.pager {
  @include flex;
  @include justifyContent(center);
  @include alignItems(center);
  margin-top: 40px;

  li {
    font-size: 1.6rem;
    line-height: 1;
    width: 36px;
    height: 36px;
    margin: 0 5px;
    text-align: center;

    a {
      color: #323232;
      padding: 10px 0;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      text-decoration: none;
      &:hover {

        color: $orange;
        border-bottom: 1px solid $orange;
      }
    }

    span {
      padding: 10px;
      display: inline-block;
      border-radius: 9999px;
      text-align: center;
      vertical-align: middle;
      width: 100%;

      &.current {
        background: $lightgray;

      }
    }

    &.prev,
    &.next {
      width: 60px;
      height: 60px;
      @include flex;
      @include justifyContent(center);
      @include alignItems(center);
      border: 1px solid #323232;
      border-radius: 50%;
      position: relative;
      transition: 0.3s;
      cursor: pointer;
      transform: scale(1.0);
      @include pc{
        min-width: 60px;
      }
      @include sp{
        width: 12vw;
        min-width: 45px;
        height: 12vw;
      }
      &::after {
        content: '';
        width: 20%;
        min-width: 20%;
        height: 16.6%;
        display: inline-block;
        position: absolute;
        background: url(/common/images/icon_arrowBlack.svg)no-repeat center/contain;
        margin: auto;
        z-index: 2;
        @include sp{
          width: 22.2%;
          min-width: 22.2%;
          height: 17.7%;
        }
      }

      &:hover {
        background: #323232;

        &::after {
          background: url(/common/images/icon_arrowWhite.svg)no-repeat center/contain;
        }
      }
    }

    &.prev {
      margin-right: 30px;

      &::after {
        transform: rotate(180deg);
      }
    }

    &.next {
      margin-left: 30px;
    }
  }

}