@import "_mixin.scss";
@import "_var.scss";
@import "_reset.scss";
@import "_mq.scss";
@import "library/_slick.scss";
// @import "library/_slick-theme.scss";

.slider {
  width: 100%;
  margin: 0 auto;

  &Nav {
    @include flex;
    @include justifyContent(center);
    @include alignItems(center);
    margin-top: 20px;
    @include sp {
      margin-top: 26px;
    }
  }

  .slick-dots {
    display: flex;
    order: 2;

    li {
      margin: 0 14px;
      @include sp {
        margin: 0 9px;
      }
    }

    button {
      display: block;
      width: 8px;
      font-size: 0;
      @include sp {
        width: 6px;
      }

      &:before {
        content: '';
        display: block;
        padding-top: 100%;
        background: $darkgray;
        border-radius: 50%;
        transition: background .3s;
      }
    }

    .slick-active button, button:hover {
      &:before { background: #323232; }
    }
  }

  .slick-arrow {
    width: 60px;
    min-width: 60px;
    height: 60px;
    font-size: 0;
    @include flex;
    @include justifyContent(center);
    @include alignItems(center);
    border: 1px solid #323232;
    border-radius: 50%;
    position: relative;
    transition: 0.3s;
    cursor: pointer;
    transform: scale(1.0);
    @include sp {
      width: 45px;
      min-width: 45px;
      height: 45px;
    }

    &::after {
      content: '';
      width: 20%;
      min-width: 20%;
      height: 16.6%;
      display: inline-block;
      position: absolute;
      background: url(/common/images/icon_arrowBlack.svg)no-repeat center/contain;
      margin: auto;
      z-index: 2;
    }

    &:hover {
      background: #323232;

      &::after {
        background: url(/common/images/icon_arrowWhite.svg)no-repeat center/contain;
      }
    }

    &.slick-prev {
      order: 1;
      margin-right: 36px;
      @include sp {
        margin-right: 30px;
      }

      &::after {
        transform: rotate(180deg);
      }
    }

    &.slick-next {
      order: 3;
      margin-left: 36px;
      @include sp {
        margin-left: 30px;
      }
    }
  }
}
