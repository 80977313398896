.table01{
 @include flex;
 width: 100%;
 border-top: 1px solid #e0e0e0;
 @include sp{
  padding: 20px 0;
  display: block;
 }
 &:last-of-type{
  border-bottom: 1px solid #e0e0e0;
 }

 dt{
  min-width: 180px;
  font-weight: $bold;
  padding: 30px 20px;
  @include pc{
    width: 20%;
  }
  @include sp{
   @include flexDirection(col);
   padding: 0px;
  }
 }

 dd{
  padding: 30px 20px;
  @include sp{
    padding: 0px;
   }
 }
}