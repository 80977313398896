.btn01 {
  a {
    display: inline-block;
    text-align: center;
    padding: 10px 0;
    border: 1px solid #333;
    background: #fff;
    border-radius: 9999px;
    transition: 0.3s;
    font-family: $font-zenmaru;
    text-decoration: none;

    &:hover {
      color: #fff;
      background: #333;
    }
  }

  &.orange {
    a {
      color: #f39700;
      background: #fff;
      border: 1px solid #f39700;
      text-indent: 0.1em;

      &:hover {
        color: #fff;
        background: #f39700;
      }
    }
  }
}

.headCnt {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 50;

  &.active {
    position: fixed;
  }

  &.open {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    .header {
      background: unset;
      border-bottom: 0px solid #000;
      position: relative;

      .humbergerLine {

        &:before,
        &:after {
          background-color: #7a7a7a;
        }
      }

      .btn01 {
        a {
          border: 1px solid #333;
          background: #fff;
          color: #333;

          &:hover {
            color: #fff;
            background: #333;
          }
        }

        &.orange {
          a {
            color: #f39700;
            background: #fff;
            border: 1px solid #f39700;

            &:hover {
              color: #fff;
              background: #f39700;
            }
          }
        }
      }

  
      .headerWrap {
        &::before {
          content: '';
          display: block;
          z-index: -1;
          background: url(/common/images/bg_header_pc.jpg)no-repeat;
          background-position: bottom 5px center;
          transition: background-position 0.4s;
          background-size: cover;
  
          @include sp {
            background: #fff;
          }
        }
      }
  
      .hdMenu {
        opacity: 1;
        pointer-events: visible;
        transform: translateY(0px);
        position: relative;
        top: 0;
        transition: opacity 0.4s, top 0.4s;
      }
    }
  }
}

.header {
  width: 100%;
  // box-shadow: 0px 0px 6px rgba(0,0,0,.5);
  z-index: 30;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;

  &Wrap {
    position: relative;
    z-index: 31;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: -1;
      background-position: bottom 5px center;
      transition: background-position 0.4s;
    }
  }

  &Inner {
    @include flex;
    @include justifyContent(sb);
    padding: 20px 30px;
    max-width: 1260px;
    margin: 0 auto;

    @include sp {
      padding: 12px 4%;
      // background: #fff;
      min-height: 50px;
      @include alignItems(center);
    }
  }

  &Logo {
    position: relative;
    z-index: 10;
    padding-left: 0px;
    @include flex;
    @include justifyContent(center);

    @include sp {
      padding-top: 0;
      @include alignItems(center);
    }

    a {
      display: block;
      width: 220px;
      height: 48px;

      @include sp {
        display: inline-block;
        width: 120px;
        height: auto;
      }
    }
  }

  &Item {
    width: calc(100% - 220px);
    margin-right: 0;
    margin-left: auto;
    @include flex;
    @include justifyContent(end);
    @include alignItems(center);

    @include sp {
      padding-top: 0;
      width: calc(100% - 120px);
    }
  }

  .btn01 {
    &.btn01 {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      display: block;

      @include pc {
        min-width: 160px;
      }

      @include sp {
        padding: 10px 25px;
      }
    }
  }

  &.top {
    background: transparent;
    border-bottom: 0px solid #000;
    position: relative;

    .humbergerLine {
      background-color: #fff;

      &:before,
      &:after {
        background-color: #fff;
      }
    }

    .btn01 {
      a {
        background: transparent;
        border: 1px solid #fff;
        color: #fff;

        &:hover {
          background: #fff;
          color: #2ea7e0;
        }
      }

      &.orange {
        a {
          background: transparent;
          border: 1px solid #fff;
          color: #fff;

          &:hover {
            background: #fff;
            color: #2ea7e0;
          }
        }
      }
    }


  }

}

.hdMenu {
  &.pcMenu {
    padding: 85px 0px 30px;
    overflow: hidden;
    transform-origin: top center;
    transform: translateY(-5px);
    pointer-events: none;
    opacity: 0;
    transition: 0s;
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
  }

  &.spMenu {
    background: #fff;
    height: calc(100% - 50px);
    padding: 18px 0px 0px;
    overflow: hidden;
    transform-origin: top center;
    transform: translateY(-5px);
    pointer-events: none;
    opacity: 0;
    transition: 0s;
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;

    .spMenuCntwrap {
      height: calc(100% - 146.5px);
      overflow-y: scroll;
    }

    .spMenuBtnwrap {
      @include flex;
      @include alignItems(center);
      @include justifyContent(center);
      @include flexWrap(wrap);
      margin-bottom: 20px;

      .btn01 {
        display: inline-block;
      }
    }

    &>div {
      width: 100%;
    }

  }

  &Submenu {
    @include flex;
    @include justifyContent(center);
    @include alignItems(center);
    margin-top: 80px;

    @include sp {
      margin-top: 35px;
      @include flexWrap(wrap);
      row-gap: 15px;
      padding-bottom: 20px;
    }

    li {
      font-size: 1.3rem;
      padding: 0 20px;
      line-height: 1;
      opacity: 1;
      transition: opacity 0.4s;

      @include sp {
        text-align: center;
        font-size: 1.2rem;
        padding: 0 5%;
        &:nth-child(even){
          border-left: 1px solid #333;
        }
      }


      &+li {
        @include pc{
          border-left: 1px solid #333;
        }
      }

      a {
        text-decoration: none;
        color: #323232;

        &:hover {
          opacity: 0.7;
        }
      }
    }

  }
}

.gnavCate {
  max-width: 1130px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 40px;
  @include flex;
  @include alignItems(end);

  // @media (max-width: 1136px) {
  //   @include justifyContent(center);
  // }

  @include sp {
    padding: 0px;
    display: block;
    width: 89.3%;
    margin: 0 auto;
  }

  &>li {
    color: #333;
    font-weight: bold;

    @include pc {
      max-width: 254px;
      width: 100%;
      padding-bottom: 20px;
    }

    

    @include sp {
      max-width: 100%;
      font-size: 1.5rem;
      border-bottom: 1px solid #f39700;
    }

    &+li {
      @include pc {
        margin-left: 1.1%;
      }
    }

    &>a {
      text-decoration: none;
      color: #323232;

      @include sp {
        position: relative;
        padding-right: 20px;
      }

      &::after {
        @include sp {
          content: '';
          width: 15px;
          height: 13px;
          display: inline-block;
          background: url(/common/images/icon_arrowBlack.svg)no-repeat center/contain;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }
      }
    }

    &>a,
    &>span {
      display: block;

      @include pc {
        border-bottom: 1px solid #f39700;
        padding: 15px 30px 10px 12px;
      }

      @include sp {
        padding-bottom: 15px;
        padding-top: 15px;
        padding-left: 0;
      }
    }
  }
  .smlBtn-external{
    a{
      font-size: 1.6rem;
      background-color: inherit;
      border-radius: 0px;

      &::after{
        background: url(/common/images/icon_externalBlack.svg) no-repeat;
        top: 50%;
        transform: translateY(-50%);
        margin: 0px;
        width: 20px;
        height: 20px;
      }
      &:hover{
        color: #323232;
        &::after{
          background: url(/common/images/icon_externalBlack.svg) no-repeat;
        }
      }
      @include sp{

      }
    }
    transition: 0s;
    &:hover{
      border-bottom: 1px solid transparent; 
    }
    @include sp{
      padding-bottom: 0px;
      display: block;
    }
  }
}

.gnavCntBox {

  position: relative;

  @include sp {
    width: 89.3%;
    margin: 0 auto;
  }
}

.gnavCnt {
  display: none;
  background: #fff;

  &>ul {
    @include flex;
    @include alignItems(center);
    @include flexWrap(wrap);

    @include pc {
      max-width: 1068px;
      margin-right: auto;
      margin-left: auto;
      padding: 40px 0;
    }

    @include sp {
      display: block;
      padding-top: 0;
      padding-bottom: 15px;
    }
  }

  li {
    color: #333;
    font-weight: 400;

    @include pc {
      width: calc(100%/4);
      padding: 0px 0px 0px 10px;
    }

    @include sp {
      padding-left: 1.5em;
    }

    // &+li {
    //   @include pc {
    //     margin-left: 1.9%;
    //   }
    // }

    &>a{
      text-decoration: none;
      color: #323232;
    }

    &>a,
    &>span {
      display: block;
      padding: 10px 0px;
    }
  }
}

.gnavCateBtn {
  cursor: pointer;

  span {
    position: relative;
    cursor: pointer;

    @include sp {
      display: block;
      width: 100%;
    }

    &::before {
      width: 5.9%;
      display: inline-block;
      background: #23318e;
      border-radius: 25%;
      position: absolute;
      top: 53.7%;
      right: 4.65%;
      margin: auto;
      height: 1px;
      content: '';

      @include sp {
        height: 2px;
        width: 15px;
        top: 0;
        bottom: 0;
        right: 0;
      }
    } 

    &::after {
      content: '';
      width: 5.9%;
      height: 1px;
      display: inline-block;
      background: #23318e;
      position: absolute;
      top: 53.7%;
      right: 4.65%;
      margin: auto;
      transform: rotate(-90deg);
      opacity: 1;
      transition: 0.3s;
      border-radius: 25%;

      @include sp {
        width: 15px; 
        top: 0;
        bottom: 0;
        height: 2px;
        right: 0;
      }
    }
  }

  &.active {
    background: #fff;

    span {
      &::after {
        opacity: 0;
        transform: rotate(0deg);
      }
    }

    &>a,
    &>span {
      border-bottom: 0px solid #f39700;
    }
  }

}

.menubg {
  &.active {
    @include sp {
      background: rgba(0, 0, 0, .7);
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 29;
    }
  }
}

//ハンバーガーメニュー
.humberger {
  height: 32px;
  margin-left: 30px;
  position: relative;
  z-index: 10;
  width: 50px;
  border: none;
  background-color: transparent;
  display: block;

  @include sp {
    width: 30px;
    height: 19px;
  }

  &.active {
    .humbergerLine {
      background-color: transparent;

      &::before {
        top: 0;
        transform: rotate(45deg);
      }

      &::after {
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }

  &Line {
    display: block;
    height: 4px;
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #7a7a7a;
    transition: 0.4s;
    border-radius: 2px;

    @include sp {
      width: 30px;
      height: 2px;
    }

    &:before,
    &:after {
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      background-color: #7a7a7a;
      border-radius: 2px;
      transition: inherit;
    }

    &:before {
      top: -16px;

      @include sp {
        top: -10px;
      }
    }

    &:after {
      top: 16px;

      @include sp {
        top: 10px;
      }
    }
  }
}

.confirmCookie {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  color: #323232;
  position: relative;
  width: 100%;
  z-index: 29;
  padding-top: 30px;
  padding-bottom: 30px;
  opacity: 0;

  @include sp {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &.active {
    opacity: 1;

    &.fadeOut {
      opacity: 0;
      display: none;
      transition: opacity 0.5s ease;
    }
  }

  &.fadeOut {
    opacity: 0;
    display: none;
    transition: opacity 0.5s ease;
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    padding: 0;
    border-bottom: 0px solid #000;
  }

  &Head {
    font-size: 1.4rem;
    color: #c81428;
    font-weight: bold;
    margin-right: 5.6%;

    @include sp {
      margin-bottom: 10px;
    }
  }

  &Body {
    font-size: 1.3rem;
    padding-right: 30px;
    @include pc{
      width: 64%;
    }
    @include sp {
      font-size: 1.2rem;
      padding-right: 0;
    }

    p {
      &:first-child {
        font-weight: bold;
        a {
          text-decoration: none;
          color: #323232;
        }
      }
    }
  }

  &Link {
    font-weight: 500;
    text-decoration: underline;
    margin-left: 0.5em;
    margin-right: 0.5em;
    display: inline-block;
  }

  &Inner {
    position: relative;
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 40px;
    @include flex;
    @include justifyContent(center);
    @include alignItems(center);

    @include sp {
      padding: 0 5.3%;
      display: block;
    }
  }

  button {
    position: absolute;
    top: 0rem;
    right: 4rem;
    width: 22px;
    height: 22px;

    @include sp {
      width: 15px;
      height: 15px;
      right: 5.3%;
    }

    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      background-color: #323232;
      width: 30px;
      height: 2px;
      top: 50%;
      left: 50%;

      @include sp {
        width: 20px;
      }
    }

    &::before {
      transform: translateX(-50%) rotate(45deg);
    }

    &::after {
      transform: translateX(-50%) rotate(-45deg);
    }
  }
}